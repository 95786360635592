import React, { useState } from "react";
import Link from "next/link";

import { IconButton, Link as MuiLink, Menu, MenuItem, Typography } from "@mui/material";

import AccountIcon from "@/components/common/icons/AccountIcon";
import { useLogoutMutation } from "@/hooks/reactQueryHooks/authHooks";
import { menuStyles } from "./styles";
import { Routes } from "@/types";

interface IProps {
  admin?: boolean;
}

const Account: React.FC<IProps> = ({ admin }) => {
  const [accountMenuEl, setAccountMenuEl] = useState<null | HTMLElement>(null);
  const openAccountMenu = Boolean(accountMenuEl);
  const { mutate: logout } = useLogoutMutation();

  const handleAccountMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAccountMenuEl(event.currentTarget);
  };
  const handleAccountMenuClose = () => {
    setAccountMenuEl(null);
  };

  const accountMenu = (
    <Menu
      id="basic-menu"
      anchorEl={accountMenuEl}
      open={openAccountMenu}
      onClose={handleAccountMenuClose}
      sx={menuStyles}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={handleAccountMenuClose}>
        <MuiLink
          component={Link}
          href={admin ? Routes.ADMIN_PROFILE : Routes.PROFILE}
          variant="h6"
          color="text.secondary"
          sx={{ textDecoration: "none", width: "100%" }}
        >
          My Profile
        </MuiLink>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleAccountMenuClose();
          logout();
        }}
      >
        <Typography variant="h6" color="text.secondary">
          Sign Out
        </Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <IconButton onClick={handleAccountMenuClick}>
        <AccountIcon />
      </IconButton>
      {accountMenu}
    </>
  );
};

export default Account;
