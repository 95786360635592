import { AxiosError } from "axios";
import { useRouter } from "next/navigation";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { toast } from "react-hot-toast/headless";

import { useGetAxiosRequests } from "@/config/axios";
import { useEnvVariables } from "@/config/EnvVariablesProvider";
import { removeSession, setSession } from "@/helpers/sessionToken";
import { authUrl } from "@/helpers";
import { RegisterFormValues } from "@/components/publicPages/Register/registerSchema";
import { DcrRegisterFormValues } from "@/components/publicPages/Register/dcrRegisterSchema";
import { TokenResponse } from "@/types/keycloak";
import { QueryKeys, Routes } from "@/types";

export const useRegisterMutation = () => {
  const { signUp } = useGetAxiosRequests();
  const router = useRouter();
  const envs = useEnvVariables();

  return useMutation<
    { error: boolean; error_description: string } | { client_id?: string },
    AxiosError<any>,
    RegisterFormValues | (DcrRegisterFormValues & { dcrClient: boolean })
  >({
    mutationFn: signUp,
    onSuccess: data => {
      if (data && "error" in data && data?.error) {
        toast.error(data?.error_description);
      } else if (data && "client_id" in data && data?.client_id) {
        toast.success(
          `Client Registered successfully !! Client Id: ${data?.client_id}. Client Registration details has been sent to your email`,
        );
      } else {
        toast.success("Thank you for signing up");
        router.replace(authUrl(envs, false));
      }
    },
    onError: error => {
      toast.error(error.response?.data.message ?? error.message);
    },
  });
};

export const useLogoutMutation = () => {
  const client = useQueryClient();
  const { logout } = useGetAxiosRequests();
  const router = useRouter();

  return useMutation({
    mutationFn: logout,
    onSuccess: () => {
      removeSession();
      // client.clear();
      client.setQueryData([QueryKeys.USER], () => null);
      window?.sessionStorage?.removeItem("country");
      router.replace(Routes.HOME);
    },
  });
};

export const useSessionMutation = () => {
  const client = useQueryClient();
  const { postSession } = useGetAxiosRequests();

  return useMutation<TokenResponse, AxiosError, { code: string; admin: boolean }>({
    mutationFn: postSession,
    onSuccess: async ({ id_token, access_token, refresh_token }, variables) => {
      setSession({
        id_token,
        access_token,
        refresh_token,
        timestamp: new Date().getTime(),
        adminSession: variables.admin,
      });
      await client.invalidateQueries({ queryKey: [QueryKeys.USER] });
    },
    onError: async error => {
      await client.invalidateQueries({ queryKey: [QueryKeys.USER] });
      toast.error(error?.code ?? "");
    },
  });
};
