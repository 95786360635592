import { Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";

export const button: SystemStyleObject<Theme> = {
  height: "33px",
  fontSize: "14px",
  fontWeight: 600,
};

export const signUpButton: SystemStyleObject<Theme> = {
  height: "33px",
  fontSize: "14px",
  fontWeight: 600,
  "&:hover": {
    color: "common.white",
  },
};

export const logo: SystemStyleObject<Theme> = {
  width: "60px",
  height: "22px",
  cursor: "pointer",
  pr: "16px",
};

export const menuStyles: SystemStyleObject<Theme> = {
  "& .MuiPaper-root": {
    width: "240px",
    filter: "drop-shadow(0px 4px 4px rgba(10, 31, 68, 0.16))",
    boxShadow: "none",
  },
};

export const logoStyles: SystemStyleObject<Theme> = {
  height: "68px",
  bgcolor: "#0E1210",
  p: "16px 24px 16px",
  borderBottom: "1px solid #6a6a6a",
};

export const logoStylesSoldo: SystemStyleObject<Theme> = {
  height: "65px",
  bgcolor: "#0E1210",
  p: "0px 24px 0px",
  borderBottom: "1px solid #6a6a6a",
};

export const logoStylesClient: SystemStyleObject<Theme> = {
  height: "64px",
  p: "12px 24px 12px",
  bgcolor: "common.white",
  display: "flex",
  alignItems: "center",
};

export const getMenuStyle = (active: boolean, isKbcApp: boolean, isRelianceApp: boolean, isEburyApp: boolean, isSpdbApp: boolean): SystemStyleObject<Theme> => ({
  color: (isKbcApp || isRelianceApp || isEburyApp) && active ? "primary.main" : (isSpdbApp && active) ? "#46A5F2" : active ? "secondary.main" : "#DBDBDB",
  "&:hover": {
    color: isKbcApp || isRelianceApp || isEburyApp ? "primary.main" : isSpdbApp ? "#46A5F2" : "secondary.main",
    "& path": {
      fill: theme => (isKbcApp || isRelianceApp || isEburyApp ? theme.palette.primary.main : isSpdbApp ? "#46A5F2" : theme.palette.secondary.main),
    },
  },
  "& path": {
    fill: theme =>
      (isKbcApp || isRelianceApp || isEburyApp) && active ? theme.palette.primary.main : (isSpdbApp && active) ? "#46A5F2" : active ? theme.palette.secondary.main : "#DBDBDB",
  },
  pl: "0",
});

export const getVerticalLine = (active: boolean, isKbcApp: boolean, isRelianceApp: boolean, isEburyApp: boolean, isCanaraBankApp: boolean, isSpdbApp: boolean): SystemStyleObject<Theme> => ({
  mr: "21px",
  height: "32px",
  width: "6px",
  bgcolor: active ? "secondary.main" : "#DBDBDB",
  borderLeft: theme =>
  isSpdbApp && active ? `6px solid #46A5F2` : active ? `6px solid ${isKbcApp || isRelianceApp || isEburyApp ? theme.palette.primary.main : theme.palette.secondary.main}` : isCanaraBankApp ? `6px solid ${theme.palette.primary.main}` :  "6px solid #0E1210",
  borderRadius: "0 4px 4px 0",
});
