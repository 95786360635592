"use client";

import React from "react";
import Link from "next/link";
import moment from "moment";

import { Typography, Stack, Container, Box, Link as MuiLink } from "@mui/material";

import { useEnvVariables } from "@/config/EnvVariablesProvider";
import { Routes } from "@/types";

const AllRightsReserved: React.FC = () => {
  const { isClientApp, companyPrivacyPolicyLink, companyTermsAndConditionsLink } = useEnvVariables();

  return (
    <Box sx={{ borderWidth: 1, borderStyle: "solid", borderColor: "divider", bgcolor: "common.white" }}>
      <Container>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography color="text.secondary" my="12px">
            &copy; {moment().format("YYYY")}. All rights reserved.
          </Typography>

          {!isClientApp && (
            <Stack direction={{ xs: "column", sm: "row" }}>
              <MuiLink
                component={Link}
                href={companyPrivacyPolicyLink || Routes.PRIVACY}
                color="text.secondary"
                underline="none"
                sx={{ mr: "24px" }}
              >
                Privacy Policy
              </MuiLink>
              <MuiLink
                component={Link}
                href={companyTermsAndConditionsLink || Routes.TERMS}
                color="text.secondary"
                underline="none"
              >
                Terms & Conditions
              </MuiLink>
            </Stack>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default AllRightsReserved;
