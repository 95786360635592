import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const AccountIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="28px" height="28px" viewBox="0 0 28 28" {...props}>
      <path
        d="M2.80722 16.7144C3.20941 18.3788 3.97318 19.9019 5.01052 21.1961C7.58212 19.2972 10.7102 18.2424 14.0005 18.2424C17.2909 18.2424 20.4186 19.2972 22.9905 21.1961C24.0279 19.9019 24.7916 18.3788 25.1938 16.7144C25.4055 15.8376 25.5157 14.9277 25.5157 14C25.5157 7.64058 20.3601 2.4848 14.0005 2.4848C7.64088 2.4848 2.48532 7.64037 2.48532 14C2.48532 14.9277 2.59551 15.8376 2.80715 16.7144H2.80722ZM21.7342 22.5315C19.5531 20.9759 16.8837 20.0608 14.0003 20.0608C11.117 20.0608 8.44766 20.9759 6.2664 22.5315C8.31042 24.3856 11.0234 25.5153 14.0003 25.5153C16.9772 25.5153 19.6901 24.3856 21.7342 22.5315ZM14.0003 27.3334C6.63678 27.3334 0.666992 21.3641 0.666992 14.0001C0.666992 6.63602 6.63626 0.666748 14.0003 0.666748C21.3644 0.666748 27.3336 6.63602 27.3336 14.0001C27.3336 21.3641 21.3644 27.3334 14.0003 27.3334V27.3334Z"
        fill="#6A6A6A"
      />
      <path
        d="M14.0007 15.212C15.6742 15.212 17.0309 13.8553 17.0309 12.1818C17.0309 10.5084 15.6742 9.15165 14.0007 9.15165C12.3272 9.15165 10.9705 10.5084 10.9705 12.1818C10.9705 13.8553 12.3272 15.212 14.0007 15.212ZM14.0007 17.0302C11.323 17.0302 9.15234 14.8596 9.15234 12.1818C9.15234 9.50412 11.323 7.3335 14.0007 7.3335C16.6784 7.3335 18.849 9.50412 18.849 12.1818C18.849 14.8596 16.6784 17.0302 14.0007 17.0302Z"
        fill="#6A6A6A"
      />
    </SvgIcon>
  );
};

export default AccountIcon;
